




















































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { lowcode } from '..'
import amTableColumnMixin from '../mixins/amTableColumnMixin'
import AmTableColumnTemplate from './AmTableColumnTemplate.vue'
import { isNil } from 'lodash'

@Component({
  components: { AmTableColumnTemplate },
  name: 'AmTableColumn',
})
export default class extends Mixins(amTableColumnMixin) {
  static componentName = 'AmTableColumn'
  getI18n(data) {
    if (isNil(data)) {
      return ''
    } else if (typeof data === 'string') {
      return data
    } else {
      return lowcode?.i18n?.get(data.i18nKey, data.value || data.label)
    }
  }

  hanldeStylekitActions(row) {
    let res =
      this.actions?.filter(
        item => !this.handleActionRule(row, item.hideRule)
      ) || []
    res = res.map(btn => {
      return {
        ...btn,
        disabled: this.handleActionRule(row, btn.disabledRule),
      }
    })
    res.sort(function (a, b) {
      // 重新排序
      if (!a.disabled && b.disabled) {
        return -1
      }
      if (a.disabled && !b.disabled) {
        return 1
      }
      return 0
    })
    return res
  }

  //操作列按钮禁用/隐藏规则
  handleActionRule(row, rule) {
    if (rule) {
      const { ruleType, bindEvent } = rule
      if (ruleType === 1) {
        return lowcode?.utils?.ruleValidate(row, rule, this)
      } else {
        return this.handleRuleFunc(bindEvent?.funcName, row)
      }
    }
    return false
  }

  handleRuleFunc(funcName, row) {
    if (!funcName) {
      return false
    }
    const _rootVM = this.getRootVm(funcName)
    if (!_rootVM[funcName]) {
      return false
    }
    return _rootVM[funcName](row)
  }

  execStylekitAction(item: any, row: any) {
    if (item.disabled) {
      return
    }
    if (!item.funcName) return
    const _rootVM = this.getRootVm(item.funcName)
    const func = _rootVM[item.funcName]
    if (typeof func !== 'function')
      throw new Error(
        `AmTableColumn action [${item.funcName}] is not a function!`
      )
    func(row)
  }
}
