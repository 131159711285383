





































import { Component, Mixins, Prop } from 'vue-property-decorator'
import ButtonMixin from '@/amui/mixins/buttonMixin'

@Component({
  name: 'AmButton',
})
export default class AmButton extends Mixins(ButtonMixin) {
  static componentName = 'AmButton'
  @Prop({ default: 200 }) wait!: number
  @Prop({ default: true }) isDebounce!: boolean
  debounceFunc

  created() {
    this.debounceFunc = this.debounce(this.$listeners.click, this.wait, true)
  }

  debounce(func, wait, immediate) {
    let timeout

    return function (context, args) {
      if (timeout) clearTimeout(timeout) // timeout 不为null
      if (immediate) {
        let callNow = !timeout // 第一次会立即执行，以后只有事件执行后才会再次触发
        timeout = setTimeout(function () {
          timeout = null
        }, wait)
        if (callNow) {
          func.apply(context, args)
        }
      } else {
        timeout = setTimeout(function () {
          func.apply(context, args)
        }, wait)
      }
    }
  }

  debounceClick() {
    const func = this.$listeners.click
    if (func) {
      if (this.isDebounce) {
        this.debounceFunc(this, arguments)
      } else {
        ;(func as any).apply(this, arguments)
      }
    }
  }
}
