





















import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'AmBizNavMain',
})
export default class AmBizNavMain extends Vue {
  static componentName = 'AmBizNavMain'
  loaded = false

  // 类型 1：pc学员端，2pc管理员
  @Prop({ default: 2 }) type!: number
  // 展示类型 1：普通 2：沉浸式（应用模式）
  @Prop({ default: 1 }) displayType!: number
  // 当前页面的导航功能编号
  @Prop({ default: 'ote_arrange_mgmt' }) functionCode!: string
  // 微应用的名称, ote、o2o、msg、kng ...
  @Prop({ default: 'o2o' }) appName!: string
  // 是否显示左侧导航
  @Prop({ default: true }) isShowLeftNav!: boolean
  // 是否显示头部导航
  @Prop({ default: true }) isShowTopNav!: boolean
  // 是否显示页脚
  @Prop({ default: true }) isShowFooter!: boolean
  // 是否显示帮助中心
  @Prop({ default: false }) isShowHelpCenter!: boolean
  // 是否自动设置标题。为true时， 导航会自动获取functionCode对应导航的名称作为页面标题
  @Prop({ default: false }) isSetTitle!: boolean
  // 是否校验权限
  @Prop({ default: true }) isCheckPermission!: boolean
  @Prop({
    default: {
      datas: [
        {
          id: '1',
          parentId: '',
          code: 'test1',
          functionCode: 'test1',
          name: '一级导航1',
          enName: '一级导航1',
          haName: 'nav top',
          showed: true,
        },
        {
          id: '2',
          parentId: '',
          code: 'test2',
          functionCode: 'test2',
          pageUrl: '',
          name: '一级导航2',
          enName: '一级导航2',
          haName: 'nav top',
          showed: true,
        },
        {
          id: '11',
          parentId: '1',
          code: 'test11',
          functionCode: 'test1',
          name: '二级导航1',
          enName: '二级导航1',
          pageUrl: '',
          haName: 'nav top',
          navIcon: 'yxt-icon-office-building',
          showed: true,
        },
        {
          id: '111',
          parentId: '11',
          code: 'test112',
          functionCode: 'test1',
          name: '三级导航1',
          enName: '三级导航1',
          pageUrl: '',
          haName: 'nav top',
          openMode: '_self',
          showed: true,
        },
        {
          id: '112',
          parentId: '11',
          code: 'ote_arrange_mgmt',
          functionCode: 'test1',
          name: '三级导航2',
          enName: '三级导航2',
          haName: 'nav top',
          pageUrl: '',
          openMode: '_self',
          showed: true,
        },
        {
          id: '113',
          parentId: '11',
          code: 'test113',
          functionCode: 'test1',
          name: '三级导航3',
          enName: '三级导航3',
          haName: 'nav top',
          pageUrl: '',
          openMode: '_blank',
          showed: true,
        },
        {
          id: '12',
          parentId: '1',
          code: 'test12',
          functionCode: 'test1',
          name: '二级导航2',
          enName: '二级导航2',
          haName: 'nav top',
          navIcon: 'yxt-icon-office-building',
          showed: true,
        },
        {
          id: '121',
          parentId: '12',
          code: 'test121',
          functionCode: 'test1',
          name: '三级导航1',
          enName: '三级导航1',
          haName: 'nav top',
          showed: true,
        },
        {
          id: '122',
          parentId: '12',
          code: 'test122',
          functionCode: 'test1',
          name: '三级导航2',
          enName: '三级导航2',
          haName: 'nav top',
          showed: true,
        },
      ],
    },
  })
  navDatas!: Record<string, any>

  get isCheckPermissionInNav() {
    return window.lowcode['__IS_APP_MAKER__'] ? false : this.isCheckPermission
  }

  mounted() {
    setTimeout(() => {
      this.loaded = true
    }, 2000)
  }
}
